import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/com/comInfo', data)
}

// 所属车场查询
export function parkingList () {
  return request.get('/system/com/managerComList')
}



// 查询运营商管理的车场集合
export function tenantManagerComList (tenantId) {
  return request.get('/system/com/tenantManagerComList?tenantId=' + tenantId)
}

//绑定 车场与租户的关系
export function bindTenant (data) {
  return request.post(`/system/com/bindTenant`,data)
}

//解除 车场与租户的关系
export function unBindTenant (data) {
  return request.post(`/system/com/unBindTenant??comId=${JSON.parse(data.comId)}&id=${data.id}&tenantId=${data.tenantId}`,data)
}
// 查询车场是否绑定
export function checkBindingTenant (comId) {
  return request.get(`/system/com/checkBindingTenant?comId=${comId}`)
}
// 获取当前运营商所有开启订单广告的车场编号
export function getAllOpenComId () {
  return request.get(`/system/orderAd/getAllOpenComId`)
}
// 运营商开启车场的订单广告服务
export function comOpen (data) {
  return request.post(`/system/orderAd/comOpen?comIds=${data.comIds}&tenantId=${data.tenantId}`,data)
}
// 运营商关闭车场的订单广告服务
export function comClose (data) {
  return request.post(`/system/orderAd/comClose?comIds=${data.comIds}&tenantId=${data.tenantId}`,data)
}

